/*
 * DiscountTable.tsx (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file DiscountTable.tsx
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useRef, useState } from 'react';
import { Column } from 'primereact/column';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import {
  ITablePayload,
  IPageEvent,
  ISortEvent
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { IDiscount } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Discount';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { currencyFormatter } from '@abstract/abstractwebcommon-shared/utils/currencyFormatter';
import { getDiscountType, getUsedOption } from './DiscountsTypes';

/**
 * Interface for DiscountsTable properties.
 */
interface IDiscountsTableProperties {
  discounts: any;
  loading: boolean;
  handleDialogOpen: any;
  handleEdit: any;
  handleDelete: (
    discountIDs: string[]
  ) => void /**<Handle delete Discount event. */;
  tablePayload: ITablePayload /**  Table payload. */;
  handlePageUpdate: (
    event: IPageEvent
  ) => void /**< Handle Table page update. */;
  handleSortUpdate: (
    event: ISortEvent
  ) => void /**< Handle sort update event. */;
  handleFilterUpdate: (
    event: string
  ) => void /**< Handle filter update event. */;
  totalRecords: number;
}

const DiscountsTable = (properties: IDiscountsTableProperties) => {
  const translation = useTranslation().t;
  const [showConfirmation, setShowConfirmation] = useState<boolean>(
    false
  ); /**< Show Confirmation Popup. */
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(
    null
  ); /**< ConfirmationPopup Target. */
  const sort: ISortEvent = properties.tablePayload?.sort; /**< Sort */
  const [selectedDiscounts, setSelectedDiscounts] = useState<
    IDiscount[] | null
  >(null); /**< Selected Discounts. */
  const deleteButtonReference: any = useRef(
    null
  ); /**< Delete Button Reference. */
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  /// Div containing header action buttons
  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer">
        <ActionButton
          variant="danger"
          onClick={(event: any) => deleteButtonClicked(event)}
          isDisabled={
            ((selectedDiscounts &&
              Object.keys(selectedDiscounts).length === 0) ||
              !selectedDiscounts) ??
            false
          }
          buttonReference={deleteButtonReference}
        />
        <ActionButton onClick={properties.handleDialogOpen} />
      </div>

      <SearchBar
        onSearchTermChanged={(data) => properties.handleFilterUpdate(data)}
      />
    </div>
  );

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Delete product on Accept
  const onAccept = () => {
    const discountIDs: string[] | undefined = selectedDiscounts?.map(
      (eachDiscount: IDiscount) => eachDiscount._id
    ); /**< discountIDs */
    properties.handleDelete(discountIDs!);
    setShowConfirmation(false);
    setSelectedDiscounts(null);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmation(false);
  };

  /// Initialize confirmation dialog
  const getConfirmationPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={translation('confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('confirm_messages.no')}
        acceptLabel={translation('confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="bottom"
      />
    );
  };

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any) => {
    const selectedValue: IDiscount[] = event.value;
    if (Array.isArray(selectedValue)) {
      const selectedRow: IDiscount[] = selectedValue.map(
        (row: IDiscount) => row
      );
      setSelectedDiscounts(selectedRow);
    }
  };

  const GetRowExpansionTemplate = (values: { rowData: IDiscount }) => (
    <>
      <tr>
        <th>{translation('discount.table.name')}</th>
        <td>{values.rowData['name']}</td>
      </tr>
      <tr>
        <th>{translation('discount.table.type')}</th>
        <td>{getDiscountType(values.rowData['discountType'])}</td>
      </tr>
      <tr>
        <th>{translation('discount.table.amount')}</th>
        <td>
          {values.rowData.amount !== 0 &&
            currencyFormatter(values.rowData['amount'])}
        </td>
      </tr>
      <tr>
        <th>{translation('discount.table.percentage')}</th>
        <td>
          {values.rowData.percentage !== 0 &&
            `${values.rowData['percentage']}%`}
        </td>
      </tr>
      <tr>
        <th>{translation('discount.table.code')}</th>
        <td>{values.rowData['code']}</td>
      </tr>
      <tr>
        <th>{translation('discount.table.used')}</th>
        <td>{getUsedOption(values.rowData.used)}</td>
      </tr>
      <tr>
        <th>{translation('discount.table.product')}</th>
        <td>{values.rowData.product ? values.rowData.product.name : null}</td>
      </tr>
      <tr>
        <th>{translation('discount.table.created')}</th>
        <td>
          {values.rowData.createdAt ? formatDate(values.rowData.createdAt) : ''}
        </td>
      </tr>
      <tr>
        <th>{translation('discount.table.updated')}</th>
        <td>
          {values.rowData.updatedAt ? formatDate(values.rowData.updatedAt) : ''}
        </td>
      </tr>
      <tr>
        <th>{translation('discount.table.usageCount')}</th>
        <td>{values.rowData['usageCount']}</td>
      </tr>
      <tr>
        <th>{translation('fields.discount.discountTerm')}</th>
        <td>{values.rowData['discountTerm']}</td>
      </tr>
      {values.rowData['duration'] ? (
        <tr>
          <th>{translation('fields.discount.duration')}</th>
          <td>{values.rowData['duration']}</td>
        </tr>
      ) : (
        <></>
      )}
    </>
  );

  const renderExpansionRows = (rowData: IDiscount) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data._id]: true });
    }
  };

  const getDataTable = () => {
    return (
      <BaseDatatable
        value={properties.discounts}
        isLoading={!properties.discounts}
        expandedRows={expandedRows}
        dataKey="_id"
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        rowExpansionTemplate={renderExpansionRows}
        sortField={sort && sort.sortField}
        sortOrder={sort && sort.sortOrder}
        first={properties.tablePayload.skip}
        rows={properties.tablePayload.limit}
        onPage={properties.handlePageUpdate}
        onSort={properties.handleSortUpdate}
        header={header}
        totalRecords={properties.totalRecords}
        selection={selectedDiscounts}
        onSelectionChange={onSelectionChange}
      >
        <Column
          expander
          className="p-0 col-width-45"
          headerClassName="p-0 col-width-45"
        />
        <Column selectionMode="multiple" className="col-width-45" />
        <Column
          field="discounts.discountName"
          header={translation('discount.table.name')}
          headerClassName="table-col-10"
          sortable
          body={(rowData) => (
            <DatatableColumn
              title={translation('discount.table.name')}
              data={rowData.name}
            />
          )}
        />
        <Column
          field="discounts.discountType"
          header={translation('discount.table.type')}
          headerClassName="d-sm-none d-xl-table-cell"
          className="d-sm-none d-xl-table-cell"
          sortable
          body={(rowData) => (
            <DatatableColumn
              title={translation('discount.table.type')}
              data={getDiscountType(rowData.discountType)}
            />
          )}
        />
        <Column
          field="discounts.amount"
          header={translation('discount.table.amount')}
          headerClassName="table-col-10"
          sortable
          className="p-col-number"
          body={(rowData) => (
            <DatatableColumn
              title={translation('discount.table.amount')}
              data={rowData.amount !== 0 && currencyFormatter(rowData.amount)}
            />
          )}
        />
        <Column
          field="discounts.percentage"
          header={translation('discount.table.percentage')}
          headerClassName="table-col-12 d-table-cell d-sm-none d-md-table-cell"
          sortable
          className="p-col-number d-table-cell d-sm-none d-md-table-cell"
          body={(rowData) => (
            <DatatableColumn
              title={translation('discount.table.percentage')}
              data={
                rowData.percentage !== 0 && (
                  <span className="price">{rowData.percentage}%</span>
                )
              }
            />
          )}
        />
        <Column
          field="discounts.discountCode"
          header={translation('discount.table.code')}
          sortable
          headerClassName="d-table-cell d-sm-none d-md-table-cell"
          className="d-table-cell d-sm-none d-md-table-cell"
          body={(rowData) => (
            <DatatableColumn
              title={translation('discount.table.code')}
              data={rowData.code}
            />
          )}
        />
        <Column
          field="discounts.used"
          header={translation('discount.table.used')}
          headerClassName="table-col-12 d-table-cell d-sm-none d-xxl-table-cell"
          className="p-col-descriptive d-table-cell d-sm-none d-xxl-table-cell"
          sortable
          body={(rowData) => (
            <DatatableColumn
              title={translation('discount.table.used')}
              data={getUsedOption(rowData.used)}
            />
          )}
        />
        <Column
          field="discounts.discountTerm"
          header={translation('fields.discount.discountTerm')}
          headerClassName="table-col-12 d-table-cell d-sm-none d-xxl-table-cell"
          className="p-col-descriptive d-table-cell d-sm-none d-xxl-table-cell"
          sortable
          body={(rowData: IDiscount) => (
            <DatatableColumn
              title={translation('fields.discount.discountTerm')}
              data={rowData.discountTerm}
            />
          )}
        />
        <Column
          field="discounts.product.productName"
          header={translation('discount.table.product')}
          headerClassName="table-col-15 d-table-cell d-sm-none d-xxl-table-cell"
          className="table-col-15 d-table-cell d-sm-none d-xxl-table-cell"
          sortable
          body={(rows) => (
            <DatatableColumn
              title={translation('discount.table.product')}
              data={rows.product ? rows.product.name : null}
            />
          )}
        />
        <Column
          field="discounts.createdAt"
          header={translation('discount.table.created')}
          className="createdDateCol d-table-cell d-sm-none d-3xl-table-cell"
          headerClassName="createdDateCol d-table-cell d-sm-none d-3xl-table-cell"
          sortable
          body={(rows) => (
            <DatatableColumn
              title={translation('discount.table.created')}
              data={rows.createdAt ? formatDate(rows.createdAt) : ''}
            />
          )}
        />
        <Column
          field="discounts.updatedAt"
          header={translation('discount.table.updated')}
          className="updatedDateCol d-table-cell d-sm-none d-3xl-table-cell"
          headerClassName="updatedDateCol d-table-cell d-sm-none d-3xl-table-cell"
          sortable
          body={(rows) => (
            <DatatableColumn
              title={translation('discount.table.updated')}
              data={rows.updatedAt ? formatDate(rows.updatedAt) : ''}
            />
          )}
        />
        <Column
          field="edit"
          body={(rowData: any) => {
            return (
              <Button
                className="custom-action-column-action-position"
                onClick={() => {
                  properties.handleEdit({ data: rowData });
                }}
                variant="outline"
              >
                <i className="bi bi-pencil-square editIcon fa-lg"></i>
              </Button>
            );
          }}
          className="col-width-45 p-0 absolute-position-responsive-screen"
        />
      </BaseDatatable>
    );
  };
  return (
    <>
      {getDataTable()}
      {getConfirmationPopup()}
    </>
  );
};

export default DiscountsTable;
