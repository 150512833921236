/*
 * Customers.ts (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file Customers.ts
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {
  fillTransactionsUserData,
  getAll,
  getAllTransactionsGroupByUsername,
  remove,
  update
} from '../Services/Customers';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const GET_CUSTOMERS_REQUEST = 'customers/fetchList/request';
const GET_CUSTOMERS_SUCCESS = 'customers/fetchList/success';
const GET_CUSTOMERS_FAILURE = 'customers/fetchList/failure';

const EDIT_CUSTOMER_REQUEST = 'customers/edit/request';
const EDIT_CUSTOMER_SUCCESS = 'customers/edit/success';
const EDIT_CUSTOMER_FAILURE = 'customers/edit/failure';

const DELETE_CUSTOMER_REQUEST = 'customers/delete/request';
const DELETE_CUSTOMER_SUCCESS = 'customers/delete/success';
const DELETE_CUSTOMER_FAILURE = 'customers/delete/failure';

const TOGGLE_CUSTOMER_DIALOG = 'customers/dialog/toggle';

// Get customer transactions group by user.
const GET_TRANSACTION_LIST_GROUPBYUSERNAME_REQUEST =
  'transactionsGroupByUsername/fetchList/request';
const GET_TRANSACTION_LIST_GROUPBYUSERNAME_SUCCESS =
  'transactionsGroupByUsername/fetchList/success';
const GET_TRANSACTION_LIST_GROUPBYUSERNAME_FAILURE =
  'transactionsGroupByUsername/fetchList/failure';

const INITIAL_STATE = {
  list: null,
  listIsFetching: true,
  customer: null,
  customerIsFetching: false,
  customerIsChanging: false,
  customerDialogOpened: false,
  skip: 0,
  limit: 50,
  count: 0,
  sort: { sortField: '_id', sortOrder: 1 } // Sort the list by username in asc by default.
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_REQUEST:
      return {
        ...state,
        listIsFetching: true,
        skip: action.payload.skip,
        limit: action.payload.limit
      };
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        listIsFetching: false,
        list: action.payload.customers,
        count: action.payload.count
      };
    case GET_CUSTOMERS_FAILURE:
      return { ...state, listIsFetching: false };
    case EDIT_CUSTOMER_REQUEST:
      return { ...state, customerIsChanging: true };
    case EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerIsChanging: false,
        customerDialogOpened: false
      };
    case EDIT_CUSTOMER_FAILURE:
      return { ...state, customerIsChanging: false };
    case DELETE_CUSTOMER_REQUEST:
      return { ...state, customerIsChanging: true };
    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerIsChanging: false,
        customerDialogOpened: false
      };
    case DELETE_CUSTOMER_FAILURE:
      return { ...state, customerIsChanging: false };
    case TOGGLE_CUSTOMER_DIALOG:
      return { ...state, customerDialogOpened: action.payload };
    case GET_TRANSACTION_LIST_GROUPBYUSERNAME_REQUEST:
      return {
        ...state,
        ...action.payload
      };
    case GET_TRANSACTION_LIST_GROUPBYUSERNAME_SUCCESS:
      return {
        ...state,
        listIsFetching: false,
        list: action.payload.userTransactions,
        count: action.payload.count
      };
    case GET_TRANSACTION_LIST_GROUPBYUSERNAME_FAILURE:
      return { ...state, listIsFetching: false };
    default:
      return state;
  }
};

const getCustomersRequest = (skip, limit) => ({
  type: GET_CUSTOMERS_REQUEST,
  payload: { skip, limit }
});

const getCustomersSuccess = (customers, count) => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: { customers, count }
});

const getCustomersFailure = () => ({
  type: GET_CUSTOMERS_FAILURE
});

const editCustomerRequest = () => ({
  type: EDIT_CUSTOMER_REQUEST
});

const editCustomerSuccess = () => ({
  type: EDIT_CUSTOMER_SUCCESS
});

const editCustomerFailure = () => ({
  type: EDIT_CUSTOMER_FAILURE
});

const deleteCustomerRequest = (customerId) => ({
  type: DELETE_CUSTOMER_REQUEST,
  payload: customerId
});

const deleteCustomerSuccess = () => ({
  type: DELETE_CUSTOMER_SUCCESS
});

const deleteCustomerFailure = () => ({
  type: DELETE_CUSTOMER_FAILURE
});

/**
 * Fetch transactions request.
 * @param skip The number of transactions to skip before taking.
 * @param limit The number of transactions to take.
 * @param searchTerm The search term
 * @param sort The property to order the transactions.
 */
const getAllTransactionsListGroupByUsernameRequest = (
  skip,
  limit,
  searchTerm,
  sort
) => {
  const payload = { skip, limit, listIsFetching: true };
  if (searchTerm) {
    payload.searchTerm = searchTerm;
  }
  payload.sort = sort;
  return {
    type: GET_TRANSACTION_LIST_GROUPBYUSERNAME_REQUEST,
    payload
  };
};

/**
 * Fetch transactions success.
 * @param userTransactions User transactions list.
 * @param count The number of transactions.
 */
const getAllTransactionsListGroupByUsernameSuccess = (
  userTransactions,
  count
) => ({
  type: GET_TRANSACTION_LIST_GROUPBYUSERNAME_SUCCESS,
  payload: { userTransactions, count }
});

/**
 * Fetch transactions failure.
 */
const getAllTransactionsListGroupByUsernameFailure = () => ({
  type: GET_TRANSACTION_LIST_GROUPBYUSERNAME_FAILURE
});

export const toggleCustomerDialog = (opened) => ({
  type: TOGGLE_CUSTOMER_DIALOG,
  payload: opened
});

export const getAllCustomers = (skip, limit) => async (dispatch) => {
  try {
    dispatch(getCustomersRequest(skip, limit));
    const response = await asyncErrorHandler(getAll(skip, limit));
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getCustomersFailure());
    } else {
      dispatch(getCustomersSuccess(response));
    }
  } catch (e) {
    dispatch(getCustomersFailure());
    handleError({ message: e.message });
  }
};

export const editCustomer = (customer) => async (dispatch) => {
  try {
    dispatch(editCustomerRequest());
    const result = await asyncErrorHandler(update(customer));
    if (result.error) {
      dispatch(editCustomerFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(editCustomerSuccess());
      dispatch(getAllCustomers());
    }
  } catch (e) {
    dispatch(editCustomerFailure());
    handleError({ message: e.message });
  }
};

export const deleteCustomer = (customerId) => async (dispatch) => {
  try {
    dispatch(deleteCustomerRequest());
    const result = await asyncErrorHandler(remove(customerId));
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(deleteCustomerFailure());
    } else {
      dispatch(deleteCustomerSuccess());
      dispatch(getAllCustomers());
    }
  } catch (e) {
    dispatch(deleteCustomerFailure());
    handleError({ message: e.message });
  }
};

/**
 * Get all transactions group by username.
 * @param skip The number of transactions to skip before taking.
 * @param limit The number of transactions to take.
 * @param searchTerm The search term
 * @param sort The property to order the transactions.
 */
export const getAllTransactionsListGroupByUsername = (
  skip,
  limit,
  searchTerm,
  sort,
  filter?: Record<string, any>
) => async (dispatch) => {
  try {
    const isAdmin = LocalStorage.getIsAdmin()?.toLowerCase(); /**< User isAdmin or not */
    dispatch(
      getAllTransactionsListGroupByUsernameRequest(
        skip,
        limit,
        searchTerm,
        sort
      )
    );
    const response = await asyncErrorHandler(
      getAllTransactionsGroupByUsername(
        skip,
        limit,
        searchTerm,
        sort,
        isAdmin ? JSON.parse(isAdmin) : false,
        filter ?? {}
      )
    ); // Get customer transactions by username.
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getAllTransactionsListGroupByUsernameFailure());
    } else {
      dispatch(
        getAllTransactionsListGroupByUsernameSuccess(
          response.userTransactions,
          response.count
        )
      );
    }
  } catch (e) {
    dispatch(getAllTransactionsListGroupByUsernameFailure());
    handleError({ message: e.message });
  }
};

/**
 * Fill user data in transactions.
 */
export const fillUserDetailsInTransactions = () => async () => {
  try {
    const response = await asyncErrorHandler(fillTransactionsUserData()); // Fill user data.

    if (response.error) {
      handleError({
        message: response.error.message || 'Failed to fill user data'
      });
    } else {
      return response;
    }
  } catch (exception) {
    handleError({ message: exception.message });
  }
};
