import { BASE_API_URL } from '../Config';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLog } from './Logs';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';

const httpClient = new HttpClient(BASE_API_URL, createLog);

/**
 * Transaction.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Jo Santiago, 2020
 *
 * @file Transaction.ts
 * @author Jo Santiago
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

export const create = (data: any) => {
  return httpClient.post(`/transactions/create`, data);
};

export const getTransactionCall = async (
  transactionId: string,
  email: string
) => {
  return httpClient.get(`/transactions/${transactionId}/${email}`);
};

/**
 * Update a transaction. Currently, this endpoint is only changing the transaction status to Settled.
 * We can use this endpoint to update more fields in the future. We just need inform the "mode" parameter.
 * @param transactionID Defines the transaction ID, which will have its status changed to Settled.
 * @returns Promise<IAPIEntityResponse<string>>
 */
export const updateTransaction = (transactionID: string): Promise<IAPIEntityResponse<string>> => {
  return httpClient.put(`/transactions/update/${transactionID}/`, {});
};

export const remove = (transactionId: string) => {
  return new Promise((resolve, reject) => {
    const url = `${BASE_API_URL}/transactions/${transactionId}`;
    const config = {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: 'application/json'
      })
    };
    return fetch(url, config)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((err) => reject(err));
  });
};

export const getTransactions = (
  skip: number,
  limit: number,
  searchTerm: any,
  sort: any,
  isAdmin: boolean,
  filter: Record<string, any>
) => {
  const url = paginationRequest(`/transactions`, {
    skip,
    limit,
    searchTerm,
    filter
  });
  return httpClient.post(url, { sort, isAdmin });
};

export const regenerateInvoiceCall = async (
  email: string,
  transactionId: string
) => {
  return httpClient.get(`/pdf/${transactionId}/${email}/true`);
};

export const markAsLicenseCreated = async (transactionId: string) => {
  return httpClient.get(`/transactions/license?transactionID=${transactionId}`);
};

/**
 * Update transaction billing information
 * @param {*} transactionID Transaction ID.
 * @param {*} data billing details billingCompanyName, billingAddress1, billingCity
  billingZipcode, billingStateOrProvince, billingCountry, billingAddress2
 * @returns
 */
export const updateBillingDetailsAPI = (transactionID: string, data: any) => {
  return httpClient.put(`/transactions/${transactionID}/customer`, data);
};
