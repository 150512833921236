/*
 * Logs.ts (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file Logs.ts
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {exportLogs, getLogs} from '../Services/Logs';
import {handleError} from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {asyncErrorHandler} from "@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler";

const GET_LOGS_REQUEST = 'logs/fetch/request';
const GET_LOGS_SUCCESS = 'logs/fetch/success';
const GET_LOGS_FAILURE = 'logs/fetch/failure';
const EXPORT_LOGS_REQUEST = 'exportLogs/fetch/request';
const EXPORT_LOGS_SUCCESS = 'exportLogs/fetch/success';
const EXPORT_LOGS_FAILURE = 'exportLogs/fetch/failure';
const RESET_STATE = 'logs/reset';

const INITIAL_STATE = {
  list: null,
  listIsFetching: false,
  skip: 0,
  limit: 50,
  count: 0,
  sort: { sortField: 'createdAt', sortOrder: -1 },
  userData: [] /**< User data. */,
  isLogExported: false /**< Is Log Exported. */,
  isExportingLog: false /**< Is exporting log */
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOGS_REQUEST:
      return { ...state, listIsFetching: true, ...action.payload };
    case GET_LOGS_SUCCESS:
      return { ...state, listIsFetching: false, ...action.payload };
    case GET_LOGS_FAILURE:
      return { ...state, listIsFetching: false };
    case EXPORT_LOGS_REQUEST:
      return { ...state, isExportingLog: true, ...action.payload };
    case EXPORT_LOGS_SUCCESS:
      return {
        ...state,
        isExportingLog: false,
        isLogExported: true,
        ...action.payload
      };
    case EXPORT_LOGS_FAILURE:
      return { ...state, isExportingLog: false };
    case RESET_STATE:
      return { ...state, isLogExported: false };
    default:
      return state;
  }
};

const getLogsRequest = (skip, limit, searchTerm, sort) => {
  const payload = { skip, limit };
  if (searchTerm) {
    payload.searchTerm = searchTerm;
  }
  if (sort) {
    payload.sort = sort;
  }
  return {
    type: GET_LOGS_REQUEST,
    payload
  };
};

const getLogsSuccess = (result) => ({
  type: GET_LOGS_SUCCESS,
  payload: { list: result.logs, userData: result.userData, count: result.count }
});

const getLogsFailure = () => ({
  type: GET_LOGS_FAILURE
});

const exportLogsRequest = (skip, limit, searchTerm, sort) => {
  const payload = { skip, limit };
  if (searchTerm) {
    payload.searchTerm = searchTerm;
  }
  if (sort) {
    payload.sort = sort;
  }
  return {
    type: EXPORT_LOGS_REQUEST,
    payload
  };
};

const exportLogsSuccess = () => ({
  type: EXPORT_LOGS_SUCCESS
});

const exportLogsFailure = () => ({
  type: EXPORT_LOGS_FAILURE
});

/// Reset state
export const resetState = () => ({
  type: RESET_STATE
});

export const fetchLogs = (
  skip,
  limit,
  searchTerm,
  sort,
  startDate,
  endDate,
  filter
) => async (dispatch) => {
  try {
    dispatch(getLogsRequest(skip, limit, searchTerm, sort));
    const result = await asyncErrorHandler(getLogs(
        skip,
        limit,
        searchTerm,
        sort,
        startDate,
        endDate,
        filter
    ));
    if (result.error) {
      handleError({ message: result.error.message });
      dispatch(getLogsFailure(result.error));
    } else {
      dispatch(getLogsSuccess(result));
    }
  } catch (e) {
    dispatch(getLogsFailure(e.message));
    handleError({ message: e.message });
  }
};

export const exportLogsAction = (
  skip,
  limit,
  searchTerm,
  sort,
  startDate,
  endDate
) => async (dispatch) => {
  try {
    dispatch(exportLogsRequest(skip, limit, searchTerm, sort));
    const result = await asyncErrorHandler(exportLogs(
        skip,
        limit,
        searchTerm,
        sort,
        startDate,
        endDate
    ));
    if (result && result.error) {
      handleError({ message: result.error.message });
      dispatch(exportLogsFailure(result.error));
    } else {
      dispatch(exportLogsSuccess());
    }
  } catch (e) {
    dispatch(exportLogsFailure(e.message));
    handleError({ message: e.message });
  }
};
