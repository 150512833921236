/*
 * RetrySubscriptionForm.tsx (AbstractECommerce)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2024
 *
 * @file RetrySubscriptionForm.tsx
 * @author Rafael Rodrigues
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import i18n from '../../../Services/I18n';
import { ISubscription } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Subscription';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import ConfirmationDialog, {
  ConfirmationDialogMode
} from '@abstract/abstractwebcommon-client/ConfirmationDialog/ConfirmationDialog';

/**
 * @interface IHandleSubmitRetrySubscriptionForm Represents the component properties for the handle submit function of the retry subscription form.
 */
interface IHandleSubmitRetrySubscriptionForm {
  transactionPrice: string /* Displays the last transaction price and holds the new transaction price to retry */;
}

/**
 * @interface IRetrySubscriptionFormProperties Represents the component properties for the entire retry subscription form.
 */
interface IRetrySubscriptionFormProperties {
  values: IHandleSubmitRetrySubscriptionForm /* Formik values of the form. */;
  errors: any /* Formik errors of the form. */;
  handleChange: (event: any) => void /* Formik on change handle. */;
  onHide: () => void /* Function to close the modal. */;
  handleSubmit: (
    values: IHandleSubmitRetrySubscriptionForm
  ) => void /* Formik on submit handle. */;
  isLoading: boolean /* Defines if the save/update was triggered. */;
  subscription: ISubscription /* Defines the subscription object the customer is retrying. */;
  handleBlur: (event: any) => void /* Formik on blur handle. */;
  touched: any /* Formik touched of the form. */;
  isRetrySubscriptionFormVisible: boolean /* Defines when to display or hide the retry modal. */;
  isProductionEnvironment?: boolean /* Indicates if the form should display the pending amount field. We should display the pending amount field only in the Local and Staging environments. */;
}

const RetrySubscriptionForm = (
  properties: IRetrySubscriptionFormProperties
) => {
  const isAdminUser: boolean = LocalStorage.getIsAdmin() !== 'false';
  const isProductionEnvironment: boolean =
    properties.isProductionEnvironment ?? true;

  return (
    <ConfirmationDialog
      dialogTitle={i18n.t(
        'adminSubscriptions.retry_subscription_form.header_title'
      )}
      isShowConfirmationModal={properties.isRetrySubscriptionFormVisible}
      onReject={() => properties.onHide()}
      onAccept={(submitValues: IHandleSubmitRetrySubscriptionForm) =>
        properties.handleSubmit(submitValues)
      }
      cancelButtonText={i18n.t(
        'adminSubscriptions.retry_subscription_form.cancel_button'
      )}
      confirmButtonText={i18n.t(
        'adminSubscriptions.retry_subscription_form.retry_button'
      )}
      alertMessage={
        <>
          {i18n.t(
            'adminSubscriptions.retry_subscription_form.warning_message_1'
          )}
          <br />
          {i18n.t(
            'adminSubscriptions.retry_subscription_form.warning_message_2'
          )}
        </>
      }
      modalMode={ConfirmationDialogMode.Warning}
    >
      <Row as={Col} className="mb-3">
        {i18n.t(
          'adminSubscriptions.retry_subscription_form.confirmation_message_1'
        )}
        <br />
        {i18n.t(
          'adminSubscriptions.retry_subscription_form.confirmation_message_2'
        )}
        <br />
        {i18n.t(
          isAdminUser
            ? 'adminSubscriptions.retry_subscription_form.confirmation_message_3_admin_side'
            : 'adminSubscriptions.retry_subscription_form.confirmation_message_3_client_side'
        )}
      </Row>

      {isAdminUser && !isProductionEnvironment ? (
        <Row>
          <Form.Group as={Col} className="mb-3">
            <InstaInputText
              label={i18n.t(
                'adminSubscriptions.retry_subscription_form.pending_amount_retry_payment'
              )}
              name="transactionPrice"
              id="transactionPrice"
              isLoading={properties.isLoading}
              onChange={(e) => properties.handleChange(e)}
              onBlur={properties.handleBlur}
              touched={properties.touched.transactionPrice}
              errors={properties.errors.transactionPrice}
              value={properties.values.transactionPrice}
            />
          </Form.Group>
        </Row>
      ) : (
        <></>
      )}
    </ConfirmationDialog>
  );
};

/**
 * Interface for IHandleSubmitRetrySubscriptionForm properties.
 */
type IWithFormikRetrySubscriptionForm = IRetrySubscriptionFormProperties;

export default withFormik({
  mapPropsToValues: (_: IWithFormikRetrySubscriptionForm) => {
    return { transactionPrice: '' };
  },
  validateOnChange: false,
  validateOnMount: false,
  validationSchema: Yup.object().shape({
    transactionPrice: Yup.number()
      .typeError('Must be a number')
      .positive()
  }),
  handleSubmit: (values, bag) => {
    return bag.props.handleSubmit({ ...values });
  }
})(RetrySubscriptionForm);
