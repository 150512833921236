/*
 * AuthenticationVerificationPage.tsx (AbstractEcommerce)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file AuthenticationVerificationPage.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { Dispatch, useEffect } from 'react';
import { signUserIntoApplicationAction } from '../../Store/UserAuth';
import {
  getConfigurationSettings,
  getSettings,
  readApplicationDetails
} from '../../Services/Settings';
import { isRole } from '../../Utils/Formatter';
import { hasApplication } from '@abstract/abstractwebcommon-client/utils/hasApplication';
import { isTokenValid } from '@abstract/abstractwebcommon-client/utils/checkTokenValid';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import {
  IMappedRole,
  IUserInformationToken,
  UserAuthenticationToken
} from '@abstract/abstractwebcommon-shared/utils/UserAuthenticationToken';
import jwtDecode from 'jwt-decode';
import { IRole } from '@abstract/abstractwebcommon-shared/interfaces/user/role';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import {
  ILogoutAllApplications,
  logoutAllApplications
} from '@abstract/abstractwebcommon-client/utils/LogoutAllApplications';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLog } from '../../Services/Logs';
import { changeUserTheme } from '@abstract/abstractwebcommon-client/utils/themeModeUtils';
import { customLocalStorageClearUtil } from '../../Utils/localStorageUtils';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { RouteName } from '../../Utils/routesNames';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { changeUserLanguage } from '@abstract/abstractwebcommon-client/utils/LanguageSettingsModeUtils';
import i18n from '../../Services/I18n';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

const AuthVerificationPage = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const search: string = useLocation().search;
  const parsedString: { [key: string]: any } = queryString.parse(search);
  const token: string = parsedString['token'];
  const redirectURL: string = parsedString['redirect_url'];
  const isLogoutRequested: boolean = parsedString['logout']; // this is user triggered logout, by clicking logout
  const autoLogoutReason: string = parsedString['autoLogoutReason']; // error to send with autoLogout
  const history: any = useHistory();
  const loggedOutapplicationUUID: string =
    parsedString['app']; /**< ApplicationUUID. */
  const isLogoutAll: boolean = parsedString['logoutAll']; /**< isLogoutAll */
  const userApplications: IApplications[] = parsedString['userApplications']
    ? JSON.parse(parsedString['userApplications'])
    : []; /**< UserApplications */
  const redirectApplicationURL: string =
    parsedString['redirect_applicationURL']; /**< Redirect application URL. */
  const themeMode: string | null = parsedString['themeMode'] ?? null;
  const languageSettingsMode: string | null =
    parsedString['languageSettingsMode'] ?? null; /**< Language settings mode */

  useEffect(() => {
    const bypassIOSBackCacheIssue = () => {
      window.onpageshow = (event: any) => {
        if (event.persisted) {
          window.location.reload();
        }
      };
    };

    bypassIOSBackCacheIssue();
  }, []);

  /// Logout Ecommerce Application.
  const logOutAction = () => {
    customLocalStorageClearUtil();
    const parameters: URLSearchParams = new URLSearchParams({
      logout: 'true',
      themeMode: LocalStorage.getThemeMode(),
      languageSettingsMode: LocalStorage.getLanguageSettingsMode()
    }); /**< Query parameters */
    window.location.href = `${
      SharedCommomRouteName.validateRoute
    }?${parameters.toString()}`;
  };

  /// Logout all applications
  const logoutAll = async () => {
    localStorage.setItem(
      'ecommerce_applicationURL',
      window.location.origin + window.location.pathname
    );
    const ecommerceApplicationURL: string = localStorage.getItem(
      'ecommerce_applicationURL'
    ) as string; /**< Ecommerce Application URL. */

    if (userApplications.length) {
      localStorage.setItem(
        'userApplications',
        JSON.stringify(userApplications)
      );
    }
    const applications: IApplications[] = JSON.parse(
      localStorage.getItem('userApplications') as string
    ); /**< User Applications. */

    const payload: ILogoutAllApplications = {
      applications: applications,
      loggedOutApplicationUUID: loggedOutapplicationUUID,
      logoutAction: logOutAction,
      redirectApplicationURL: ecommerceApplicationURL
    };
    await asyncErrorHandler(logoutAllApplications(payload));
  };

  // Based on role,redirected to the routes.
  const redirectRoutes = (
    isAdmin: boolean,
    userRole: string[],
    adminRoleUUID: string,
    productManagerRoleUUID: string,
    salesRoleUUID: string
  ): string => {
    let url: string;
    if (isAdmin) {
      if (isRole([adminRoleUUID], userRole)) {
        url = RouteName.adminDashboardRoute; // 'ECOMMERCE_ADMIN': redirect to dashboard.
      } else if (isRole([productManagerRoleUUID], userRole)) {
        url = RouteName.adminProductsRoute; // 'ECOMMERCE_PRODUCT_MANAGER': redirect to products page.
      } else if (isRole([salesRoleUUID], userRole)) {
        url = RouteName.adminCustomersRoute; // 'ECOMMERCE_SALES': redirect to customers page.
      }
    } else {
      url = RouteName.clientTransactionsRoute; // non-admin(ROLE_USER): redirect to client.
    }
    return url;
  };

  /// Fetch Configuration Settings
  const fetchConfigurationSettings = async () => {
    const result: IAPIEntityResponse<Record<
      string,
      any
    >> = await asyncErrorHandler(getConfigurationSettings());
    const configurationSettings: Record<string, any> = result.data || {};
    // If there are settings to configure, go to the configuration page to configure the application.
    if (
      configurationSettings &&
      Object.keys(configurationSettings).length > 0
    ) {
      history.push({
        pathname: SharedCommomRouteName.configurationSettingsRoute,
        state: configurationSettings
      });
    } else {
      // Otherwise, go to the dashboard page.
      history.push({ pathname: RouteName.adminDashboardRoute });
    }
  };

  const verifyUser = async () => {
    const finalThemeMode: string =
      LocalStorage.getThemeMode() ?? ThemeMode.lightMode;
    const finalLanguageSettingsMode: string =
      LocalStorage.getLanguageSettingsMode() ?? LanguageSettingsMode.english;

    const application: IAPIEntityResponse<IApplications> = await asyncErrorHandler(
      readApplicationDetails()
    ); // Get application.
    const settings: any = await asyncErrorHandler(getSettings()); // Get settings.

    if (
      application &&
      application.application &&
      Object.keys(application.application).length > 0 &&
      settings &&
      settings.store &&
      Object.keys(settings.store).length > 0
    ) {
      const applicationUUID: string = application.application.applicationUUID;
      const userAuthenticationVerificationURL: string =
        settings.store.userAuthenticationVerificationURL;
      const adminRoleUUID: string = settings.store.adminRoleUUID;
      const productManagerRoleUUID: string =
        settings.store.productManagerRoleUUID;
      const salesRoleUUID: string = settings.store.salesRoleUUID;
      const commonParameters: URLSearchParams = new URLSearchParams({
        app: applicationUUID,
        themeMode: finalThemeMode,
        languageSettingsMode: finalLanguageSettingsMode
      }); /**< Commong query parameters */
      const logoutParameters: URLSearchParams = new URLSearchParams({
        ...Object.fromEntries(commonParameters),
        logout: 'true'
      }); /**< Logout query parameters */

      const invalidTokenAuthVerificationURLParameters: URLSearchParams = new URLSearchParams(
        {
          ...Object.fromEntries(commonParameters),
          autoLogoutReason: '403'
        }
      );
      if (!isStringEmptyOrNullOrUndefined(redirectURL)) {
        invalidTokenAuthVerificationURLParameters.append(
          'redirect_url',
          redirectURL
        );
      }

      const invalidTokenAuthVerificationURL: string = `${userAuthenticationVerificationURL}?${invalidTokenAuthVerificationURLParameters.toString()}`;

      LocalStorage.setApplicationUUID(applicationUUID);

      const logoutRequestedParameters: URLSearchParams = new URLSearchParams({
        ...Object.fromEntries(logoutParameters)
      });
      if (!isStringEmptyOrNullOrUndefined(redirectURL)) {
        logoutRequestedParameters.append('redirect_url', redirectURL);
      }
      if (isLogoutRequested) {
        window.location.href = `${userAuthenticationVerificationURL}?${logoutRequestedParameters.toString()}`;
      }

      if (token) {
        if (isTokenValid(token)) {
          const decodedToken: IUserInformationToken = new UserAuthenticationToken(
            jwtDecode(token),
            true
          ).getUserAuthenticationToken();

          //check if user has the application
          const isApplicationIncluded: boolean = hasApplication(
            decodedToken.applications as string[],
            applicationUUID
          );
          if (isApplicationIncluded) {
            const roles: IMappedRole[] = decodedToken.roles as IMappedRole[]; // user roles.
            const roleUUIDs: string[] = roles.map((role: IRole) => {
              return role.roleUUID;
            }) as string[]; // user roleUUIDs.
            // check if user is an admin
            const isAdmin: boolean = isRole(
              [adminRoleUUID, productManagerRoleUUID, salesRoleUUID],
              roleUUIDs
            );

            //sign user into the application
            dispatch(
              signUserIntoApplicationAction({
                token,
                isAdmin
              })
            );
            if (
              redirectURL &&
              redirectURL !== undefined &&
              redirectURL !== 'undefined'
            ) {
              window.location.href = redirectURL;
            } else {
              const url: string = redirectRoutes(
                isAdmin,
                roleUUIDs,
                adminRoleUUID,
                productManagerRoleUUID,
                salesRoleUUID
              );
              if (url === RouteName.adminDashboardRoute) {
                fetchConfigurationSettings();
              } else {
                window.location.href = url;
              }
            }
          } else {
            const logoutWithoutAppParameters: URLSearchParams = new URLSearchParams(
              {
                logout: 'true',
                themeMode: finalThemeMode,
                languageSettingsMode: finalLanguageSettingsMode
              }
            ); /**< Query parameters */
            window.location.href = `${userAuthenticationVerificationURL}?${logoutWithoutAppParameters.toString()}`;
          }
        } else {
          window.location.href = invalidTokenAuthVerificationURL;
        }
      } else {
        if (autoLogoutReason) {
          const autoLogoutParameters: URLSearchParams = new URLSearchParams({
            ...Object.fromEntries(logoutParameters),
            autoLogoutReason: autoLogoutReason
          }); /**< Autologout query parameters */
          window.location.href = `${userAuthenticationVerificationURL}?${autoLogoutParameters.toString()}`;
        } else if (isLogoutRequested) {
          window.location.href = `${userAuthenticationVerificationURL}?${logoutRequestedParameters.toString()}`;
        } else if (isLogoutAll) {
          /// Logout from external application
          customLocalStorageClearUtil();
          const logoutAllParameters: URLSearchParams = new URLSearchParams({
            ...Object.fromEntries(commonParameters),
            logoutAll: 'true'
          }); /**< LogoutAll query parameters */

          if (
            redirectApplicationURL &&
            redirectApplicationURL !== undefined &&
            redirectApplicationURL !== 'undefined'
          ) {
            window.location.href = `${redirectApplicationURL}?${logoutAllParameters.toString()}`;
          } else {
            window.location.href = `${userAuthenticationVerificationURL}?${logoutAllParameters.toString()}`;
          }
        } else {
          const userAuthenticationVerificationURLParameters: URLSearchParams = new URLSearchParams(
            {
              ...Object.fromEntries(commonParameters)
            }
          );
          if (!isStringEmptyOrNullOrUndefined(redirectURL)) {
            userAuthenticationVerificationURLParameters.append(
              'redirect_url',
              redirectURL
            );
          }
          window.location.href = `${userAuthenticationVerificationURL}?${userAuthenticationVerificationURLParameters.toString()}`;
        }
      }
    }
  };

  useEffect(() => {
    if (themeMode) {
      changeUserTheme(themeMode);
      LocalStorage.setThemeMode(themeMode);

      //Fire a storage event to update theme mode
      window.dispatchEvent(new Event('storage'));
    }

    if (languageSettingsMode) {
      changeUserLanguage(languageSettingsMode, i18n);
      LocalStorage.setLanguageSettingsMode(languageSettingsMode);

      //Fire a storage event to update language settings mode
      window.dispatchEvent(new Event('storage'));
    }

    if (
      (isLogoutAll && userApplications.length) ||
      (isLogoutAll && loggedOutapplicationUUID)
    ) {
      logoutAll(); /**< Logout all application */
    } else {
      verifyUser();
    }
  }, [getSettings, isTokenValid, signUserIntoApplicationAction]);

  return <></>;
};

export default withErrorBoundary(AuthVerificationPage, createLog);
