/*
 * UserAuth.ts (AbstractEcommerce)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2021
 *
 * @file UserAuth.ts
 * @author James Ugbanu
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {
  createSlice,
  createAsyncThunk,
  ThunkDispatch,
  AnyAction,
  ActionCreatorWithNonInferrablePayload,
  ActionCreatorWithoutPayload
} from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { IAuth } from '@abstract/abstractwebcommon-shared/interfaces/auth';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

export const AUTH_FEATURE_KEY = 'userAuth';

const INITIAL_STATE: IAuth = {
  isCheckingLogin: false,
  loginError: null,
  username: null,
  fullName: null,
  isAuthenticated: false,
  isAdmin: false, // To check user is admin or not.
  isAuthChecked: false,
  refreshToken: '',
  isChangingPassword: false,
  accessToken: null,
  isReconnecting: false
};

export const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    loginRequest(state) {
      state.isCheckingLogin = true;
      state.loginError = null;
    },
    loginSuccess(state, action: any) {
      const accessToken: string = action.payload.accessToken;
      const email: string = action.payload.email || null;
      const userUUID: string = action.payload.userUUID || null;
      const username: string = action.payload.username || null;
      const fullName: string = action.payload.fullName || null;

      const userRole = action.payload.roles.map((role: any) => {
        return role.roleUUID;
      }); /**< User roles. */
      const isAdmin: boolean = action.payload.isAdmin || false; // User is admin or not.

      LocalStorage.setXAuthToken(accessToken);
      LocalStorage.setXUserUUID(userUUID);
      LocalStorage.setFullName(fullName);
      LocalStorage.setEmail(email);
      LocalStorage.setUserName(username);
      LocalStorage.setRole(JSON.stringify(userRole)); /**< User role. */
      LocalStorage.setAdmin(
        JSON.stringify(isAdmin).toLowerCase()
      ); /**< isAdmin. */
      state.username = username;
      state.fullName = fullName;
      state.isAuthenticated = true;
      state.isAuthChecked = true;
      state.isCheckingLogin = false;
      state.loginError = null;
      state.accessToken = accessToken;
      state.isAdmin = isAdmin;
      if (action.payload.refreshToken) {
        // on rememberMe
        state.refreshToken = action.payload.refreshToken;
        localStorage.setItem(
          'x-auth-refresh-token',
          `${action.payload.data.refreshToken}`
        );
      }
    },
    loginFailure(state) {
      state.isCheckingLogin = false;
    },
    logout(state) {
      LocalStorage.removeXAuthToken();
      LocalStorage.removeXUserUUID();
      LocalStorage.removeIsAdmin();
      LocalStorage.removeFullName();
      LocalStorage.removeUserName();
      LocalStorage.removeEmail();
      LocalStorage.removeRole();

      state.isAuthenticated = false;
      state.isAdmin = false;
      state.isAuthChecked = false;
      state.username = null;
      state.fullName = null;
      state.accessToken = null;
    },
    reconnectRequest(state) {
      state.isReconnecting = true;
    },
    reconnect(state) {
      const fullName: string | null = LocalStorage.getFullName();
      const username: any | null = LocalStorage.getUserName();
      const token: string | null = LocalStorage.getXAuthToken();
      const isAdmin:
        | string
        | undefined = LocalStorage.getIsAdmin()?.toLowerCase();
      if (token && username) {
        state.username = username;
        state.fullName = fullName;
        state.isAuthenticated = true;
        state.isAuthChecked = true;
        state.accessToken = token;
        state.isAdmin = isAdmin ? JSON.parse(isAdmin) : false;
      }
      state.isReconnecting = false;
    }
  },
  extraReducers: {}
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;

export const logoutAction = createAsyncThunk(
  'auth/signout',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { logout } = authActions;
    dispatch(logout());
  }
);

export const reconnectAction = createAsyncThunk(
  'auth/signin',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { reconnect, reconnectRequest } = authActions;
    dispatch(reconnectRequest());
    dispatch(reconnect());
  }
);

/**
 * sign in auths Action.
 * @param payload
 */
export const signUserIntoApplicationAction = createAsyncThunk(
  'auth/signin',
  async (payload: { token: string; isAdmin: boolean }, thunkAPI) => {
    const dispatch: ThunkDispatch<unknown, unknown, AnyAction> =
      thunkAPI.dispatch;
    const token: string = payload.token;
    const isAdmin: boolean = payload.isAdmin;
    const loginFailure:
      | ActionCreatorWithNonInferrablePayload<string>
      | ActionCreatorWithoutPayload<string> = authActions.loginFailure;
    const loginSuccess:
      | ActionCreatorWithNonInferrablePayload<string>
      | ActionCreatorWithoutPayload<string> = authActions.loginSuccess;
    try {
      const decodedToken: any = jwtDecode(token);
      const authPayload: any = {
        accessToken: token,
        email: decodedToken.email,
        fullName: `${decodedToken.firstName} ${decodedToken.lastName}`,
        userUUID: decodedToken.userUUID,
        application: decodedToken.application,
        username: decodedToken.username,
        roles: decodedToken.roles,
        isAdmin
      };
      dispatch(loginSuccess(authPayload));
    } catch (exception) {
      dispatch(loginFailure());
      handleError({ message: exception.message });
    }
  }
);

export const getAuthState = (rootState: any) => rootState[AUTH_FEATURE_KEY];
